import moment from 'moment';
import { Genders } from './Genders';
import { UserTableDto } from './User';
import { MedicalTestTypeValue } from './MedicalTest';

export default interface Patient {
  address: string;
  birthdate?: string;
  city: string;
  country: string;
  creationDate: string;
  deleted: boolean;
  email: string;
  gender: Genders;
  id: string;
  image: string;
  language: string;
  modificationDate: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  privacyDate: string;
  specialistIds: string[];
  surname: string;
  useLicenseDate: string;
  userId: string;
  inactive: boolean;
  lastActivity: string;
  wearables: boolean;
  nutritionalPlan: boolean;
  medicalTestInProgress: MedicalTestTypeValue;
  hasAnyChat: boolean;
  coupon: string;
}

export interface PatientDto extends Patient {
  permission: string;
  specialists: UserTableDto[];
}

class PatientFormDto {
  name: string;
  surname: string;
  language: string;
  email: string;
  specialistIds: string[];

  constructor(patient: PatientDto) {
    this.name = patient.name;
    this.surname = patient.surname;
    this.email = patient.email;
    this.language = patient.language;
    this.specialistIds = patient.specialists?.map<string>((specialist) => specialist.professionalId);
  }
}

export function updatePatientFromPatientFormDto(patient: Patient, patientformDto: PatientFormDto): Patient {
  const newPatient = Object.assign({}, patient);
  newPatient.name = patientformDto.name;
  newPatient.surname = patientformDto.surname;
  newPatient.specialistIds = patientformDto.specialistIds;
  newPatient.language = patientformDto.language;
  return newPatient;
}

export class PatientInformationFormDto {
  address: string;
  birthdate: any;
  city: string;
  country: string;
  email: string;
  gender: Genders;
  name: string;
  phoneNumber: string;
  postalCode: string;
  surname: string;
  inactive: boolean;

  constructor(patient: Patient) {
    this.address = patient.address;
    this.birthdate = patient.birthdate ? new Date(patient.birthdate) : undefined;
    this.city = patient.city;
    this.country = patient.country;
    this.email = patient.email;
    this.gender = patient.gender;
    this.name = patient.name;
    this.phoneNumber = patient.phoneNumber;
    this.postalCode = patient.postalCode;
    this.surname = patient.surname;
    this.inactive = patient.inactive;
  }
}

export function updatePatientWithPatientsInformation(
  patient: Patient,
  patientInformation: PatientInformationFormDto,
): Patient {
  const newPatient = Object.assign({}, patient);
  newPatient.address = patientInformation.address;
  newPatient.birthdate = moment(patientInformation.birthdate).format('YYYY-MM-DDTHH:mm:ss');
  newPatient.city = patientInformation.city;
  newPatient.country = patientInformation.country;
  newPatient.email = patientInformation.email;
  newPatient.gender = patientInformation.gender;
  newPatient.name = patientInformation.name;
  newPatient.phoneNumber = patientInformation.phoneNumber;
  newPatient.postalCode = patientInformation.postalCode;
  newPatient.surname = patientInformation.surname;
  newPatient.inactive = patientInformation.inactive;
  return newPatient;
}

interface PatientResume {
  total: number;
  active: number;
  inMedicalTestInProgress: number;
  wearables: number;
}

export { Patient, PatientFormDto, PatientResume };
